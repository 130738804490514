<template>
<v-app>
  <v-main>
    <slot></slot>
  </v-main>
</v-app>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
